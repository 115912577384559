import { useLoaderData } from "react-router-dom";

import AsyncTable from "components/AsyncTable";
import FilterByDate from "components/FilterByDate";
import PastSessionItem from "components/PastSessionItem";
import { NOT_PRESENT, JOINED } from "components/PresencePill";
import SessionLegendTooltip from "components/SessionLegendTooltip";
import CheckCircleIcon from "assets/icons/check-circle-outline.svg";
import FaceRetouchingOffIcon from "assets/icons/face-retouching-off.svg";
import SwapCallsIcon from "assets/icons/swap-calls.svg";
import { oneWeekAgoFromTodayISO, todayISO } from "utils/index";

const PastSessionsView = () => {
  const { pastSessionsPromise } = useLoaderData();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold text-zinc-700 flex items-center">
          Past Sessions
          <SessionLegendTooltip
            items={[
              {
                icon: (
                  <div className="w-[10px] h-[10px] rounded-full bg-red-600" />
                ),
                label: "Tutor not present",
              },
              {
                icon: (
                  <div className="w-[10px] h-[10px] rounded-full bg-yellow-400" />
                ),
                label: "Tutor was present then left",
              },
              {
                icon: (
                  <div className="w-[10px] h-[10px] rounded-full bg-green-500" />
                ),
                label: "Tutor is present",
              },
              {
                icon: <FaceRetouchingOffIcon className="w-full " />,
                label: "Not present",
              },
              {
                icon: <CheckCircleIcon className="w-full " />,
                label: "Present",
              },
              {
                icon: <SwapCallsIcon className="w-full " />,
                label: "Swapped out",
              },
            ]}
          />
        </h3>
        <FilterByDate
          defaultEndDate={todayISO()}
          defaultStartDate={oneWeekAgoFromTodayISO()}
        />
      </div>
      <AsyncTable
        columns={[
          { id: "participants", label: "Participants" },
          { id: "date", label: "Date", className: "text-right sm:pr-6" },
        ]}
        noDataMessage="There are no sessions to display."
        paginationAriaLabel="All sessions pagination"
        resolve={pastSessionsPromise}>
        {row => (
          <PastSessionItem
            admin={row.admin}
            date={row.date}
            key={row.uuid}
            startTime={row.start_time}
            student={row.student}
            studentStatus={JOINED}
            studentUuid={row.student_uuid}
            tutor={row.sub_tutor ?? row.primary_tutor}
            tutorStatus={row.tutor_status || NOT_PRESENT}
            tutorUuid={row.sub_tutor_uuid ?? row.primary_tutor_uuid}
            userSessionOverrideClaimed={row.user_session_override_claimed}
            uuid={row.uuid}
          />
        )}
      </AsyncTable>
    </div>
  );
};

export default PastSessionsView;
